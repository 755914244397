/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
 module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false,
  },
  icons: {
    type: 'default',
    custom: {
      ic_chevron_right: {
        viewbox: '0 0 37 37',
        content: '<g fill="none" fill-rule="evenodd" transform="rotate(-90 18.5 18.5)"><circle cx="18.5" cy="18.5" r="18.5" fill="#fff" /><path fill="currentColor" d="M24.88 15.343c-.189-.188-.494-.188-.683 0l-6.013 6.025-6.025-6.025c-.19-.177-.487-.171-.671.013-.184.184-.19.48-.013.671l6.355 6.355c.09.092.214.142.342.141.128 0 .25-.051.342-.141l6.355-6.355c.2-.189.2-.495.011-.684z"/></g>'
      }
    }
  }
}
